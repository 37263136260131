<template>
  <div class="recovery width100">
    <v-card elevation="0" max-width="500" rounded="lg" class="pa-12 width100" v-if="card == 'recovery'">
      <div class="primary--text text--darken-2 mb-10 link" @click="back">
        <v-icon class="mb-1" color="primary darken-2">mdi-arrow-top-left mdi-rotate-315 mdi-18px</v-icon> Back to Log In
      </div>
      <div class="f28 font-weight-bold mb-1">Password recovering</div>
      <div class="text--text mb-7 f18 font-weight-medium">
        Please enter the email address you have used to Sign in. You will receive the email with the Password Reset Instructions.
        Additionally, you can contact the platform administrator.
      </div>
      <v-form @submit.prevent="reset" ref="form" lazy-validation>
        <v-text-field
          class="field48"
          v-model="email"
          placeholder="Email"
          :error-messages="emailErrors"
          outlined
          dense
          required
          color="primary"
          height="48"
        ></v-text-field>
        <div class="text-center">
          <v-btn depressed height="48" type="submit" block large color="primary">
            <span class="f15 font-weight-bold">Continue</span>
          </v-btn>
        </div>
      </v-form>
    </v-card>
    <v-card elevation="0" max-width="500" min-height="440" rounded="lg" class="pa-12 width100" v-if="card == 'success'">
      <div class="primary--text text--darken-2 mb-14 link" @click="back">
        <v-icon class="mb-1" color="primary darken-2">mdi-arrow-top-left mdi-rotate-315 mdi-18px</v-icon> Back to Log In
      </div>
      <img class="d-block mx-auto" src="@/assets/icon/success.svg" alt="success" />
      <div class="f28 font-weight-bold mt-5 text-center">Password reset instruction was send on your email</div>
    </v-card>
    <v-card elevation="0" max-width="500" min-height="440" rounded="lg" class="pa-12 width100" v-if="card == 'fail'">
      <div class="primary--text text--darken-2 mb-14 link" @click="back">
        <v-icon class="mb-1" color="primary darken-2">mdi-arrow-top-left mdi-rotate-315 mdi-18px</v-icon> Back to Log In
      </div>
      <img class="d-block mx-auto" src="@/assets/icon/fail.svg" alt="fail" />
      <div class="f28 font-weight-bold mt-5 text-center">You used all available attempts to reset your password. Try again later</div>
    </v-card>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      card: 'recovery',
      email: '',
      error: [],
    };
  },
  methods: {
    notifi(btn) {
      if (btn == 'invalid') {
        this.$notify({
          title: 'Incorrect email',
          message: 'The email you entered is incorrect. Please try again or reset the password',
          type: 'error',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    back() {
      this.$router.push('/sign-in');
    },
    async reset() {
      this.error = [];
      const data = new Object();
      data.email = this.email;
      await this.$store
        .dispatch('passwordReset', data)
        .then(() => {
          this.card = 'success';
        })
        .catch((e) => {
          this.error = e.response.data.error;
          if (this.error.find((i) => i == 'password_reset__limit')) {
            this.card = 'fail';
          }
        });
    },
  },
  computed: {
    emailErrors() {
      const errors = [];
      this.error.find((item) => item == 'email__required') && errors.push('Please enter your email');
      this.error.find((item) => item == 'email__invalid') && errors.push('Provided email is not valid');
      this.error.find((item) => item == 'staff__not_found') && errors.push('Sorry, but user with the provided email is not found');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
  },
};
</script>
